import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../Components/Card/Card';
import styles from './Home.module.scss';

function Home(props) {
  return (
    <section id="home" className={styles.home}>
      <div className={styles['home__container']}>
        <Card className={styles['home__card']}>
          <h1 className={styles['home__title']}>
            Hello
            <br />I am Gichul Jaun
          </h1>
          <h2 className={styles['home__subtitle']}>
            A Web Developer in Toronto, Ontario
          </h2>
          <Link to="/contact" className={styles['home__link']}>
            Contact Me
          </Link>
        </Card>
      </div>
    </section>
  );
}

export default Home;
