import React from 'react';
import { projectList } from '../../projectList/projectList';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import arrow from '../../Assets/Icons/arrow_forward.svg';
import styles from './Carousel.module.scss';

function Carousel(props) {
  return (
    <Splide
      aria-label="my works"
      options={{
        perPage: 1,
        rewind: true,
        drag: 'free',
        autoplay: 'true',
        interval: 2000,
        pauseOnFocus: 'true',
        lazyLoad: 'nearby',
      }}
    >
      {projectList.map((item) => {
        return (
          <SplideSlide key={item.id} className={styles.splide}>
            <div className={styles.carousel}>
              <div className={styles['carousel__content']}>
                <p className={styles['carousel__text']}>{item.text}</p>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className={styles['carousel__link']}
                >
                  Live Link
                  <img src={arrow} alt="forward arrow" />
                </a>
              </div>
              <LazyLoadImage
                src={item.img}
                alt={`snapshot for ${item.title}`}
                title={`snapshot for ${item.title}`}
                className={styles['carousel__img']}
                effect="opacity"
                placeholderSrc={item.img}
                data-splide-lazy-srcset={item.img}
              />
            </div>
          </SplideSlide>
        );
      })}
    </Splide>
  );
}

export default Carousel;
