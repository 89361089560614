import React from 'react';
import { NavLink } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import './Sidebar.scss';

function Sidebar(props) {
  return (
    <Menu right width={280}>
      <NavLink to="/">Home</NavLink>
      <NavLink to="/about">About</NavLink>
      <NavLink to="/skills">Skills</NavLink>
      <NavLink to="/works">Works</NavLink>
      <NavLink to="/contact">Contact</NavLink>
    </Menu>
  );
}

export default Sidebar;
