import React from 'react';
import styles from './Form.module.scss';

function Form(props) {
  return (
    <form
      name="contact"
      method="POST"
      data-netlify="true"
      className={styles.form}
    >
      <input type="hidden" name="form-name" value="contact" />
      <label htmlFor="user-name" className="sr-only">
        user-name
      </label>
      <input
        type="text"
        id="user-name"
        required
        placeholder="Name"
        name="user-name"
        className={styles['form__input']}
      />
      <label htmlFor="user-email" className="sr-only">
        user-email
      </label>
      <input
        type="email"
        id="user-email"
        required
        placeholder="Email"
        name="user-email"
        className={styles['form__input']}
      />
      <label htmlFor="comments" className="sr-only">
        comments
      </label>
      <textarea
        name="comments"
        id="comments"
        required
        placeholder="Comments"
        className={styles['form__textarea']}
      ></textarea>
      <button type="submit" className={styles['form__button']}>
        Send
      </button>
    </form>
  );
}

export default Form;
