import React from 'react';
import styles from './Card.module.scss';

function Card(props) {
  if (props.title) {
    return (
      <div className={`${styles.card} ${props.className}`}>
        <h1 className={styles['card__title']}>{props.title}</h1>
        {props.children}
      </div>
    );
  } else {
    return (
      <div className={`${styles.card} ${props.className}`}>
        {props.children}
      </div>
    );
  }
}

export default Card;
