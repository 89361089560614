import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../Components/Card/Card';
import profile from '../../Assets/Images/profile-resize-min.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from './About.module.scss';

function About(props) {
  return (
    <section id="about" className={styles.about}>
      <div className={styles['about__container']}>
        <Card title="About Me">
          <div className={styles['about__flexbox']}>
            <div className={styles['about__content']}>
              <p className={styles['about__text']}>
                I design and build beautiful and modern websites. By combining
                knowledge and best practices in web development and design that
                I gained from studies and works, I provide user-friendly and
                accessible web sites and applications. Along with skills listed
                here, I love to learn new libraries, tools and concepts in web
                development and web design.
              </p>
              <Link to="/contact" className={styles['about__link']}>
                Contact Me
              </Link>
            </div>
            <div className={styles['about__imgcontent']}>
              <LazyLoadImage
                src={profile}
                alt="profile"
                title="profile"
                className={styles['about__img']}
                effect="blur"
                placeholderSrc={profile}
              />
            </div>
          </div>
        </Card>
      </div>
    </section>
  );
}

export default About;
