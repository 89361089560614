import React from 'react';
import Card from '../Card/Card';
import styles from './Footer.module.scss';

function Footer(props) {
  return (
    <footer className={styles.footer}>
      <div className={styles['footer__container']}>
        <Card className={styles['footer__card']}>
          <div className={styles['footer__line']}></div>
          <p>
            Designed and Developed by
            <a
              href="https://www.linkedin.com/in/gichuljaun/"
              target="_blank"
              rel="noreferrer"
            >
              Gichul Jaun
            </a>
            <span>, 2022</span>
          </p>
        </Card>
      </div>
    </footer>
  );
}

export default Footer;
