import atreo from '../Assets/Images/atreo-snap.png';
import teamApp from '../Assets/Images/teamapp-snap-min.png';
import chatApp from '../Assets/Images/chatapp-snap-min.png';
import htmlPuzzle from '../Assets/Images/htmlpuzzle-snap-min.png';
import vidMonster from '../Assets/Images/vid-monster-snap-min.png';
import shopperMapper from '../Assets/Images/shopper-mapper-snap.png';
import studioGhibli from '../Assets/Images/studio-ghibli-snap.png';
import changingFont from '../Assets/Images/changing-font-snap.png';

export const projectList = [
  {
    id: 1,
    title: 'atreo-media',
    text: 'This is a revised and updated website for Atreo media with subscription-based pricing plans. A modern and interactive website with business highlights, a log-in system, pricing plans, portfolio examples and email contact. Built with React, Firebase, and Adobe XD',
    img: atreo,
    link: 'https://www.atreomedia.com/',
  },
  {
    id: 1,
    title: 'teamApp',
    text: 'A full website design and build for a concept team collaboration platform. This website also includes a CMS blog. Built with Webflow and Figma',
    img: teamApp,
    link: 'https://team-app-f7f7e2.webflow.io/',
  },
  {
    id: 2,
    title: 'chatApp',
    text: 'A website for a concept project - a chat application. Designed the page first from Figma and built a responsive website in Webflow.',
    img: chatApp,
    link: 'https://chatapp-landing-page-98aca7.webflow.io/',
  },
  {
    id: 3,
    title: 'html-puzzle',
    text: 'Player need to solve a HTML tag puzzle. A timer starts once the player press the start button and they can check their score when the game ends. Built with React and Firebase.',
    img: htmlPuzzle,
    link: 'https://gichul0317.github.io/react-puzzle-firebase/',
  },
  {
    id: 4,
    title: 'vid-monster',
    text: 'Vid-Monster is a retro style game application. Player needs to defeat the vid-monster by choosing various actions such as "social distancing" and "wash hands". Built with React and Firebase.',
    img: vidMonster,
    link: 'https://vid-monster.netlify.app/',
  },
  {
    id: 5,
    title: 'shopper-mapper',
    text: 'Shopper-Mapper finds restaurants or bars located within 10km of your address and provides directions. This site is built with React and MapQuest API, and pair-programmed with Paula Poirier, Tejaswini Veena and Shouvik Roy.',
    img: shopperMapper,
    link: 'https://practical-meitner-05cbb0.netlify.app/',
  },
  {
    id: 6,
    title: 'studio-ghibli',
    text: "You can watch trailers of Studio Ghibli's animations and view story outlines, running times and ratings in this Studio Ghibli app. This site is built with studio ghibli API and pair-programmed with Shouvik Roy.",
    img: studioGhibli,
    link: 'https://studioghibli-project.netlify.app/',
  },
  {
    id: 7,
    title: 'changing-font',
    text: 'You can type text, change font size and letter spacing, and select different fonts and colors to test it out. This site is built with jQuery.',
    img: changingFont,
    link: 'https://gichul0317.github.io/changing-fonts/',
  },
];
