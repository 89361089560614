import React from 'react';
import styles from './Skillset.module.scss';

function Skillset(props) {
  return (
    <div className={styles.skillset}>
      <div className={styles['skillset__skill']}>
        <div className={styles['skillset__desc']}>
          <span>HTML</span>
          <span>99%</span>
        </div>
        <div className={styles['skillset__bar']}>
          <div
            className={styles['skillset__value']}
            style={{ width: '99%' }}
          ></div>
        </div>
      </div>
      <div className={styles['skillset__skill']}>
        <div className={styles['skillset__desc']}>
          <span>CSS</span>
          <span>98%</span>
        </div>
        <div className={styles['skillset__bar']}>
          <div
            className={styles['skillset__value']}
            style={{ width: '98%' }}
          ></div>
        </div>
      </div>
      <div className={styles['skillset__skill']}>
        <div className={styles['skillset__desc']}>
          <span>Sass</span>
          <span>97%</span>
        </div>
        <div className={styles['skillset__bar']}>
          <div
            className={styles['skillset__value']}
            style={{ width: '97%' }}
          ></div>
        </div>
      </div>
      <div className={styles['skillset__skill']}>
        <div className={styles['skillset__desc']}>
          <span>JavaScript</span>
          <span>96%</span>
        </div>
        <div className={styles['skillset__bar']}>
          <div
            className={styles['skillset__value']}
            style={{ width: '96%' }}
          ></div>
        </div>
      </div>
      <div className={styles['skillset__skill']}>
        <div className={styles['skillset__desc']}>
          <span>React</span>
          <span>94%</span>
        </div>
        <div className={styles['skillset__bar']}>
          <div
            className={styles['skillset__value']}
            style={{ width: '94%' }}
          ></div>
        </div>
      </div>
      <div className={styles['skillset__skill']}>
        <div className={styles['skillset__desc']}>
          <span>Webflow</span>
          <span>87%</span>
        </div>
        <div className={styles['skillset__bar']}>
          <div
            className={styles['skillset__value']}
            style={{ width: '87%' }}
          ></div>
        </div>
      </div>
      <div className={styles['skillset__skill']}>
        <div className={styles['skillset__desc']}>
          <span>Figma</span>
          <span>90%</span>
        </div>
        <div className={styles['skillset__bar']}>
          <div
            className={styles['skillset__value']}
            style={{ width: '90%' }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default Skillset;
