import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../Components/Card/Card';
import Skillset from '../../Components/Skillset/Skillset';
import styles from './Skills.module.scss';

function Skills(props) {
  return (
    <section id="skills" className={styles.skills}>
      <div className={styles['skills__container']}>
        <Card title="Skills">
          <div className={styles['skills__flexbox']}>
            <div className={styles['skills__content']}>
              <p className={styles['skills__text']}>
                I have built projects using HTML5, CSS3, Sass, JavaScript,
                React, Firebase and Webflow that are mobile-friendly and
                compliant with accessibility guidelines. I am studying web
                development and design materials to keep myself engaged in
                self-improvements.
              </p>
              <Link to="/contact" className={styles['skills__link']}>
                Contact Me
              </Link>
            </div>
            <Skillset />
          </div>
        </Card>
      </div>
    </section>
  );
}

export default Skills;
