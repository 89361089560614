import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../Assets/Images/portfolio-logo.png';
import Sidebar from '../Sidebar/Sidebar';
import styles from './Header.module.scss';

function Header(props) {
  return (
    <header className={styles.navbar}>
      <Sidebar />
      <nav>
        <div className={styles['navbar__container']}>
          <NavLink to="/">
            <img
              src={Logo}
              alt="portfolio logo"
              className={styles['navbar__logo']}
            />
          </NavLink>
          <ul className={styles['navbar__menu']}>
            <li className={styles['navbar__list']}>
              <NavLink
                to="/"
                className={(navData) =>
                  navData.isActive
                    ? `${styles['navbar__item--active']}`
                    : `${styles['navbar__item']}`
                }
              >
                Home
              </NavLink>
            </li>
            <li className={styles['navbar__list']}>
              <NavLink
                to="/about"
                className={(navData) =>
                  navData.isActive
                    ? `${styles['navbar__item--active']}`
                    : `${styles['navbar__item']}`
                }
              >
                About
              </NavLink>
            </li>
            <li className={styles['navbar__list']}>
              <NavLink
                to="/skills"
                className={(navData) =>
                  navData.isActive
                    ? `${styles['navbar__item--active']}`
                    : `${styles['navbar__item']}`
                }
              >
                Skills
              </NavLink>
            </li>
            <li className={styles['navbar__list']}>
              <NavLink
                to="/works"
                className={(navData) =>
                  navData.isActive
                    ? `${styles['navbar__item--active']}`
                    : `${styles['navbar__item']}`
                }
              >
                Works
              </NavLink>
            </li>
            <li className={styles['navbar__list']}>
              <NavLink
                to="/contact"
                className={(navData) =>
                  navData.isActive
                    ? `${styles['navbar__item--active']}`
                    : `${styles['navbar__item']}`
                }
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
