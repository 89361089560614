import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Skills from './Pages/Skills/Skills';
import Works from './Pages/Works/Works';
import Contact from './Pages/Contact/Contact';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Container from './Components/Container/Container';
import './App.css';

// const Home = React.lazy(() => import('./Pages/Home/Home'));
// const About = React.lazy(() => import('./Pages/About/About'));
// const Skills = React.lazy(() => import('./Pages/Skills/Skills'));
// const Works = React.lazy(() => import('./Pages/Works/Works'));
// const Contact = React.lazy(() => import('./Pages/Contact/Contact'));
// const Header = React.lazy(() => import('./Components/Header/Header'));
// const Footer = React.lazy(() => import('./Components/Footer/Footer'));

function App() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>gichul-j.com</title>
        <meta name="description" content="portfolio for gichul-j" />
        <meta name="author" content="developed by gichul jaun" />
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Container>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/works" element={<Works />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Container>
    </React.Fragment>
  );
}

export default App;
