import React from 'react';
import Card from '../../Components/Card/Card';
import Carousel from '../../Components/Carousel/Carousel';
import styles from './Works.module.scss';

function Works(props) {
  return (
    <section id="works" className={styles.works}>
      <div className={styles['works__container']}>
        <Card title="My Works">
          <Carousel />
        </Card>
      </div>
    </section>
  );
}

export default Works;
