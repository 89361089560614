import React from 'react';
import Card from '../../Components/Card/Card';
import Form from '../../Components/Form/Form';
import htmlImg from '../../Assets/Images/html-key-min.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from './Contact.module.scss';

function Contact(props) {
  return (
    <section id="contact" className={styles.contact}>
      <div className={styles['contact__container']}>
        <Card className={styles['contact__card']}>
          <div className={styles['contact__content']}>
            <h1 className={styles['contact__title']}>Contact</h1>
            <Form />
          </div>
          <div className={styles['contact__imgcontent']}>
            <LazyLoadImage
              src={htmlImg}
              alt="keyboard keys for word HTML"
              title="keyboard keys for word HTML"
              className={styles['contact__img']}
              effect="blur"
              placeholderSrc={htmlImg}
            />
          </div>
        </Card>
      </div>
    </section>
  );
}

export default Contact;
